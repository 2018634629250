import React from "react"
//import { Link } from "gatsby"
import Navbar from "./navbar.js"

const Layout = (props) => {
  const children = props.children  
  return (
      
      <>
        
        <header><Navbar/></header>
        <main>{children}</main>

      </>
    )
  }


export default Layout
