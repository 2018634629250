import React, {useState} from "react"
import { Link } from "gatsby"

const Navbar = (props) => {
  const [expanded, setExpand] = useState(false);
let a;
if(expanded) {
  a ="over1"
} else {
  a="over2"
}
  return (
    <nav>
     
      <div id="overlay" className={a}>
        <div className="eight">
          <button style={{margin:0, background: "url(/icons/x.svg)", backgroundSize:"cover", border:0, width:"32px", height:"32px"}} onClick={()=>{setExpand(!expanded)}}/>

          <div className="menu">
            <div><Link to="/" onClick={()=>{setExpand(!expanded)}} style={{textDecoration:"None"}}>Home</Link></div>
            <div><Link to="/about" onClick={()=>{setExpand(!expanded)}} style={{textDecoration:"None"}}>About</Link></div>
            <div><Link to="/pub" onClick={()=>{setExpand(!expanded)}} style={{textDecoration:"None"}}>Publications</Link></div>
          </div>
        </div>
      </div>
    <button style={{ margin:0,background: "url(/icons/list.svg)", backgroundSize:"cover", border:0, width:"32px", height:"32px"}} onClick={()=>{setExpand(!expanded)}}/>
    </nav>
  )
}

export default Navbar;