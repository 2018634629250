// custom typefaces
//import "typeface-montserrat"
//import "typeface-merriweather"

//import "prismjs/themes/prism.css"
import React from "react"
import Layout from './src/components/layout';

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);